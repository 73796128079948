<template>
  <div id="picCont" v-if="subImgs.length && !modal">
    <Imgs
      :cnt="cnt"
      @remFav="remFav"
      @addFav="addFav"
      :favsArr="favsArr"
      :favs="favs"
      :imgsArr="subImgs"
    />
  </div>
</template>

<script>
import axios from "axios";
import Imgs from "@/components/imgs.vue";
export default {
  name: "Pics",
  components: {
    Imgs,
  },
  data() {
    return {
      favsArr: [],
      subImgs: [],
      favs: false,
      cat: "young",
      cnt: 0,
      cats: [],
      title: null,
      modal: false,
    };
  },
  async mounted() {
    let { data } = await axios.get("/myapi/list/");
    this.cats = data;
    this.subImgsSet();
    window.onkeydown = ({ key }) => this.setKeys(key);
    this.getFavs();
  },
  computed: {},
  watch: {
    favsArr(n) {
      this.title = n.length + " " + "favs";
      if (this.favs) document.title = this.title;
    },
  },
  methods: {
    async subImgsSet() {
      let { data } = await axios.post("/myapi", { cat: this.cat });
      this.subImgs = data;
    },
    setKeys(key) {
      key == "f"
        ? (this.favs = !this.favs)
        : key == "m"
        ? (this.modal = !this.modal)
        : key == "u"
        ? (this.cnt += 2)
        : () => {};
    },
    remFav(i) {
      if (this.favsArr.length > 1) {
        let arr = this.favsArr.filter((img) => !img.src.includes(i.src));
        this.setFavs(arr);
      }
    },
    setFavs(arr) {
      window.localStorage.setItem("favs", JSON.stringify(arr));
      this.getFavs();
    },
    getFavs() {
      let lsFav = window.localStorage.getItem("favs");
      this.favsArr = lsFav ? JSON.parse(lsFav) : [];
    },

    addFav(url) {
      let favMap = this.favsArr.map((i) => i.src);
      if (!this.favs && !favMap.includes(url.src)) {
        this.setFavs([url, ...this.favsArr]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#picCont {
  background: black;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
}
</style>