<template>
  <div v-if="topImg" class="imgCont">
    <img
      id="topImg"
      @contextmenu.prevent="saveFav"
      :class="wide ? 'wide' : 'tall'"
      @load="imgLoaded($el)"
      :src="topImg.replace('new_big', 'full')"
    />

    <div id="botCont" v-if="subImgsArr.length && !favs">
      <img
        @click="topImg = i.replace('new_small', 'full')"
        @load="subImgLoaded"
        @contextmenu.prevent="saveFav"
        v-for="i in subImgsArr"
        :src="i"
        :key="i"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["img", "favs"],
  data() {
    return {
      cnt: 0,
      wide: false,
      subImgsArr: [],
      subLoaded: 0,
      topImg: null,
    };
  },
  mounted() {
    this.topImg = this.img.src;
  },
  methods: {
    destroy() {
      this.getSub(this.img.href);
    },
    async saveFav(ev) {
      let { data } = await axios.post("/myapi/savefav/", {
        url: ev.target.src,
      });
    },
    async getSub(url) {
      let { data } = await axios.post("/myapi/sub/", {
        url: url,
        og: this.img.src,
      });
      this.subImgsArr = data;
    },
    subImgLoaded(ev) {
      let nh = ev.target.naturalHeight;
      let nw = ev.target.naturalWidth;
      let cl = nh < nw ? "wide" : "tall";
      this.subLoaded++;
      document.title = this.subLoaded;
      ev.target.classList.add(cl);
    },
    imgLoaded(e) {
      if (!this.cnt) {
        this.cnt++;
        this.getSub(this.img.href);
        this.wide = e.children[0].naturalHeight < e.children[0].naturalWidth;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (hover: hover) {
  img.wide {
    min-width: 100vw;
    max-width: 100vw;
  }
  img.tall {
    min-width: 51vw;
    max-width: 100vw;
  }
  .v-lazy-image {
    filter: blur(10px) grayscale(0.2) brightness(0.75);
    transition: 0.7s;
  }
  .v-lazy-image-loaded {
    transition-delay: 0.1s;
    filter: blur(0) grayscale(0) brightness(1);
  }
  .hide {
    display: none;
  }
}
@media (hover: none) {
  .imgCont {
    background: black;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100vw;
    display: gird;
    grid-template-rows: 7fr 3fr;
  }
  #topImg {
    align-self: stretch;
    max-height: 100%;
    grid-row: 1;
    opacity: 0.1;
  }
  #botCont {
    grid-row: 2;
    display: grid;
    grid-auto-flow: column;
    align-items: stretch;
    min-width: 100%;
    overflow-x: scroll;
    img {
      align-self: stretch;
    }
  }
  img {
    opacity: 0.1;
  }
  /* touch stuff goes here */
}
</style>