<template>
  <div class="imgsCont">
    <!-- <imgComp
      :favs="favs"
      :class="hideFav(img.src)"
      @remFav="$emit('remFav', img)"
      @addFav="$emit('addFav', img)"
      v-for="img in favs ? favsArr : imgsArr"
      :key="img"
      :img="img"
    /> -->
    <imgComp
      :key="cnt"
      :favs="favs"
      :class="hideFav(imgsArr[cnt].src)"
      @remFav="$emit('remFav', imgsArr[cnt])"
      @addFav="$emit('addFav', imgsArr[cnt])"
      :img="imgsArr[cnt]"
    />
  </div>
</template>

<script>
import imgComp from "@/components/img.vue";
export default {
  props: ["imgsArr", "favs", "favsArr", "cnt"],
  components: { imgComp },
  data() {
    return {};
  },
  methods: {
    hideFav(img) {
      let favMap = this.favsArr.map((i) => i.src);
      if (favMap.includes(img) && !this.favs) {
        return "hidden";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
div.imgsCont {
  min-height: 100vh;
  min-width: 100vw;
  text-align: center;
  background: black;
}
</style>